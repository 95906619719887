import { Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Licenses = () => (
  <>
    <Layout>
      <header className="header pt-8 pb-4 bg-dark text-white text-center">
        <div className="container">
          <h1>Licenses</h1>
        </div>
      </header>
      <section className="section py-9 text-center">
        <div className="container">
          <table className="table table-bordered lead-1">
            <tbody>
              <tr>
                <td className="py-6">
                  <b>Arizona</b>
                </td>
                <td className="py-6">
                  Consumer Lender License: <b>#CL-1018370</b>
                </td>
                <td style={{ maxWidth: "300px" }} className="py-6">
                  Arizona Department of Insurance and Financial Institutions 100
                  N. 15th Avenue, Suite 261 Phoenix, AZ 85007-2630
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
    <Seo
      title="Trustic | Our Licenses"
      description="Your questions answered about guarantor personal loans from Trustic."
    />
  </>
)

export default Licenses
